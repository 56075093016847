<template>
    <div>
      <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
      <div
        class="d-flex align-items-center justify-content-between search-list-area"
      >
        <div class="d-flex align-items-center search-input mb-4">
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <select v-model="list_length" class="form-control ml-2">
            <option value="5" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div>
          <b-button variant ="outline-success" class="mr-4" :to="{ name: 'is-ortaklari-ekle' }">İş Ortağı Ekle</b-button>

<b-button variant="outline-primary"   v-b-toggle.sidebar-right>Filtrele</b-button>

        </div>
      </div>
  
      <div class="custom-list">
          <List
            :header="header"
            :data="items"
            :pagination="pagination"
            :loading="loading"
            @updatePage="updatePage"
            moduleName="İş Ortakları Listesi"
            :moduleCreateEvent="true"
            :moduleSearch="search"
          >
        
         
          
          <template v-slot:item.Durum="{ item }">
                    <div v-html="$customFunctions.activeBadgeString(item.Durum)"></div>
          </template>
         
        
          <template v-slot:item.activeStatus="{ item }">
            <div>
                <b-button :to="{ name: 'is-ortaklari-calisanlar' }" variant="primary">Detay</b-button>
            </div>  
        </template>
        <!-- <template v-slot:item.IlgilendigiLokasyon="{ item }">
          <div>
            {{ item.IlgilendigiLokasyon }} {{ item.Adres }}
          </div>
        </template> -->

        </List>
        <!-- Create and Actions Popups -->
      </div>
      <div class="notes-sidebar top-full">
          <b-sidebar id="sidebar-right" right>
            <div class="card dveb-gray-card aside-gray-card tab-card" style="overflow-y:auto">
              <div class="card-header">
                FİLTRELE
                
              </div>
              <b-tabs content-class="mt-3">
              <b-tab content-class="tab-content" title="Filtre" active>
                  <div class="form-group">
                      <label for="sahip">Sahibi</label>
                      <select name="" id="sahip" class="form-control" >
                          <option value="" selected>Seçiniz</option>
                          <option value="">Şekerbank A.Ş</option>
                      </select>
                  </div>
                  <div class="form-group">
                      <label for="izle">İzlenecek</label>
                      <select name="" id="izle" class="form-control" >
                          <option value="" selected>Seçiniz</option>
                          <option value="">Evet</option>
                          <option value="">Hayır</option>
                      </select>
                  </div>
                  <div class="form-group">
                      <label for="durum">Durum</label>
                      <select name="" id="durum" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">1</option>
                          <option value="">2</option>
                          <option value="">3</option>
                      </select>
                      
                  </div>
                  <div class="form-group">
                      <label for="il">İl</label>
                      <select name="" id="il" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">1</option>
                          <option value="">2</option>
                          <option value="">3</option>
                      </select>
                      
                  </div>
                  <div class="form-group">
                      <label for="ilce">İlçe</label>
                      <select name="" id="ilce" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">1</option>
                          <option value="">2</option>
                          <option value="">3</option>
                      </select> 
                  </div>
                  <div class="form-group">
                      <label for="anahat">Anahtar Durumu</label>
                      <select name="" id="anahat" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">Var</option>
                          <option value="">Yok</option>
                          <option value="">3</option>
                      </select> 
                  </div>
                  <div class="form-group">
                      <label for="anahat" class="form-label">İşgalci Durumu</label>
                      <select name="" id="anahat" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">Var</option>
                          <option value="">Yok</option>
                          <option value="">3</option>
                      </select> 
                  </div>
                  <div class="form-group">
                      <label for="anahat">Ada</label>
                      <input type="text" class="form-control">
                  </div>
                  <div class="form-group">
                      <label for="anahat">Parsel</label>
                      <input type="text" class="form-control">
                  </div>
                  <div class="row">
                      <div class="col-md-6 col-12 mt-4">
                          <b-button variant="light" class="w-100" >
                              Temizle
                          </b-button>
                      </div>
                      <div class="col-md-6 col-12 mt-4">
                          <b-button variant="light" class="w-100">
                              Uygula
                          </b-button>
                      </div>
                      <div class="col-12 mt-4">
                          <b-button variant="light" class="w-100">
                              Bu Filtreyi Kaydet
                          </b-button>
                      </div>
                  </div>
              </b-tab>
              <b-tab title="Hızlı Ara">
                  <div
                  class="card mt-4 dveb-card-white"
                  style="border-radius: 0 !important"
                >
                  <div>
                    <h4> Filtre Örnek </h4>
                    <hr>
                    <div class="row ml-auto">
                      <b-button variant="success" class="mr-4" >
                        Uygula
                      </b-button>
                      <b-button variant="danger" class="" @click="openDeleteHandler(key)">
                        Sil
                      </b-button>
                    </div>
                  </div>
              </div>
              </b-tab>
            </b-tabs>
  
            </div>
          </b-sidebar>
      </div>
      <b-modal v-model="showDetailModal" id="modal-1" title="Çalışan Bilgileri">
                    <table >
                        <tbody>
                            <tr>
                                <td style="width: 40%;">
                                <b>Ad Soyad</b>
                                </td>
                                <td>: {{selectedItem.SorumluKisi}}</td>
                            </tr>
                            <tr>
                                <td>
                                <b>Hizmet Türü </b>
                                </td>
                                <td>: {{selectedItem.hizmet}}</td>
                            </tr>
                            <tr>
                                <td>
                                <b>Email </b>
                                </td>
                                <td>: {{ selectedItem.email }}</td>
                            </tr>
                            <tr>
                                <td>
                                <b>Telefon </b>
                                </td>
                                <td>: {{ selectedItem.telefon }}</td>
                            </tr>
                            <tr>
                                <td>
                                <b>Hizmet Bölgesi </b>
                                </td>
                                <td>: {{  selectedItem.bolge }}</td>
                            </tr>
                            <tr>
                                <td>
                                <b>Açık Adres</b>
                                </td>
                                <td>: {{ selectedItem.adres }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <template  #modal-footer>
                        <div>
                            <button class="btn btn-success mr-4" @click="showDetailModal = false">Kaydet</button>
                            <button class="btn btn-info" @click="showDetailModal = false">çık</button>

                        </div>
                    </template>
                </b-modal>
      <DeleteOperation
      />
    </div>
  </template>
  
  <style lang="scss" scoped>
  @media (max-width: 990px) {
    .search-list-area {
      flex-direction: column;
      .search-input {
        width: 100%;
        * {
          width: 100%;
        }
      }
    }
    .search-buttons {
      flex-direction: column;
    }
  }
  
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
  }
  .tapu-text {
    span {
      background-color: $dveb-divider;
      padding: 0.2rem 0.5rem;
      border-radius: 0.3rem;
    }
  }
  </style>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    
    name: "customerList",
    
    data() {
      return {
        search: "",
        filters: "",
        showDetailModal: false,
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames : ["Hazırlık","Satış","Devir"],
        header: [
          { text: "Firma Adı", sortable:true,value: "TicariUnvan" },
          { text: "Sorumlu Adı", sortable:true,value: "SorumluKisi" },
          // { text: "Hizmet Türü", value: "hizmet" },
          { text: "Email", value: "Eposta" },
          { text: "Telefon", value: "Telefon" },
          { text: "Hizmet Bölgesi", value: "IlgilendigiLokasyon" },
          { text: "Adres", value: "Adres" },
          { text: 'Durum', value: 'Durum', size: '80px' },
          { text: "", value: "activeStatus" },


          
        //   { text: "İşlemler", value: "action" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList:[],
        list_length:5,
        
      };
    },
  
    created() {
      this.search = this.$route.query.search
      this.search = this.search.toLowerCase()
      this.getList();
  
    },
    methods: {
      getList() {
      this.loading = true;

      this.items = [];
      this.totalItems = 0;
      this.loading = true;

      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `isortaklari_list?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&token=${this.myUser}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data;
            this.totalItems = res.data?.length;
            this.pagination = {
              current_page: this.page,
              total_pages: 3,
              total_items: this.totalItems,

            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      openDeleteHandler(key) {
        this.$bvModal.show('modal-deleteOperation');
        let index = this.notes.indexOf(key)
        this.notes.splice(index,1)
      },
      showDetailView(item){
        this.selectedItem  = item
        this.showDetailModal = true
      }
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length:function(){
        this.getList()
      },
      $route:function(){
        this.search = this.$route.query.search
      this.search = this.search.toLowerCase()
      }
      
  
    },
    computed: {
    ...mapGetters(["myUser"]),
  },
  };
  </script>
  